import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"

import { graphql, HeadProps, PageProps } from "gatsby"

import { Layout } from "../components/Layout"
import { Hero } from "../components/Hero"
import { Team } from "../components/Team"

type DataProps = {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const IndexPage = ({ data: { site } }: PageProps<DataProps>) => {
  return (
    <Layout>
      <Hero />
      <section>
        <div class='container my-24 mx-auto px-6'>
          <h2 class='mb-16 text-center text-3xl font-bold text-gray-800'>
            Wir sind weiterhin Ihr Steuerberater in Berlin-Charlottenburg
          </h2>
          <div class='flex flex-wrap justify-center'>
            <div class='mb-8 w-full max-w-lg md:w-1/2'>
              <div class='flex flex-col items-center'>
                <div class='mb-5 flex h-20 w-20 items-center justify-center rounded-full bg-[#627d98]'>
                  <svg
                    class='h-10 w-10 text-white'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    // SVG of a house
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M5 13l4 4L19 7'
                    ></path>
                  </svg>
                </div>
                <h3 class='mb-2 text-xl font-medium text-gray-800'>
                  Gleiche Adresse
                </h3>
                <p className='mb-4 text-center text-gray-600'>
                  Wir befinden uns auch als SUTAX weiterhin in der{" "}
                  <span class='font-bold'>
                    Wundtstr.&nbsp;48-50, 14057&nbsp;Berlin-Charottenburg
                  </span>
                </p>
              </div>
            </div>
            <div class='w-full max-w-lg md:w-1/2'>
              <div class='flex flex-col items-center'>
                <div class='mb-5 flex h-20 w-20 items-center justify-center rounded-full bg-[#627d98]'>
                  <svg
                    className='h-10 w-10 text-white'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M5 13l4 4L19 7'
                    ></path>
                  </svg>
                </div>
                <h3 class='mb-2 text-xl font-medium text-gray-800'>
                  Größeres Team
                </h3>
                <p class='mb-5 text-center text-gray-600'>
                  Seit SUTAX sind wir ein größeres Team, das Ihnen {` `}
                  <span class='font-bold'>
                    noch mehr Kompetenz und Erfahrung
                  </span>
                  {` `}bietet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Team />
    </Layout>
  )
}

export default IndexPage

export function Head(props: HeadProps<DataProps>) {
  return <title>{props.data.site.siteMetadata.title}</title>
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
