import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Team = () => (
  <div class='container my-24 mx-auto px-6'>
    <section class='mb-32 text-center text-gray-800'>
      <h2 class='mb-12 text-3xl font-bold'>
        Unser{" "}
        <a href='https://sutax.de/team/'>
          <u class='text-[#627d98]'>Team</u>
        </a>{" "}
        wurde bei SUTAX vergrößert
      </h2>

      <div class='lg:gap-xl-12 mx-auto grid max-w-3xl gap-x-6 sm:grid-cols-2'>
        <div class='mb-12'>
          <StaticImage
            class='mx-auto mb-6 w-36 rounded-lg shadow-lg'
            src='../images/Zemann-Zipser.jpg'
            alt='Zemann-Zipser'
          />
          <h5 class='mb-4 text-lg font-bold'>Ute Zemann-Zipser</h5>
          <p class='mb-0 text-[#627d98]'>Gesellschafterin / </p>
          <p class='mb-6 text-[#627d98]'>Steuerberaterin</p>
        </div>
        <div class='mb-12 lg:mb-0'>
          <StaticImage
            class='mx-auto mb-6 w-36 rounded-lg shadow-lg'
            src='../images/Krings.jpg'
            alt='Krings'
          />
          <h5 class='mb-2 text-lg font-bold'>Sebastian Krings</h5>
          <p class='mb-0 text-[#627d98]'>Gesellschafter / </p>
          <p class='mb-6 text-[#627d98]'>Steuerberater</p>
        </div>
      </div>
    </section>
  </div>
)
